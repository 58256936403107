import React, { useContext } from "react"
import Icon1 from "../assets/img/icons/performance-macbook.svg"
import Icon2 from "../assets/img/icons/wi-fi-off.svg"
import Icon3 from "../assets/img/icons/multiple-devices.svg"
import Icon4 from "../assets/img/icons/widgetsmith.svg"
import Icon5 from "../assets/img/icons/maintenance.svg"
import Icon6 from "../assets/img/icons/installing-updates.svg"
import Icon7 from "../assets/img/icons/speed.svg"
import Icon8 from "../assets/img/icons/widgetsmith.svg"
import Icon9 from "../assets/img/icons/services.svg"
import Icon10 from "../assets/img/icons/laravel.svg"
//import MyLink from "../components/link"
import Introduction from "../components/intro"
import AgencyFriendly from "../components/agencyFriendly"
import Process from "../components/process"
import GetInTouch from "../components/getInTouch"
import SubNav from "../components/subNav"
import RecentWork from "../components/recentWork"
import Artwork from "../assets/img/artwork/artwork-02.svg"
import processJson from "../json/processes/web-application-development"
import { ModalContext } from "../components/modalContext"

export default function Page() {
    const modalContext = useContext(ModalContext);

    return (
        <div className="service-page">
            <Introduction Artwork={Artwork}>
                <h1><span>Web Application</span> Development.</h1>
                <p className="text-larger"><strong>Progressive Web Applications built on trusted technologies.</strong></p>
                <p className="mt-12 mb-0">
                    <a href="#examples" className="btn btn-lg btn-blue">View Examples</a>
                    <a href="#get-in-touch" className="btn btn-lg btn-blue">Get in Touch</a>
                </p>
            </Introduction>

            <SubNav
                title="Web Application Development"
                nav={['Features', 'Use Cases', 'Examples', 'Process', 'Get in Touch']}
            />

            {/* Features */}
            <div id="features" className="component top-gradient-darker double-padding">
                <div className="container">
                    <h3 className="text-center font-normal cl-white">We produce web apps that are:</h3>

                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6">
                        <div className="mt-8">
                            <Icon1  />
                            <h3 className="cl-primary mt-5">Highly Performant</h3>
                            <p>We build PWAs as a single page application (SPA) allowing minimal page loads and only the necessary server requests.</p>
                        </div>
                        <div className="mt-8">
                            <Icon2 />
                            <h3 className="cl-primary mt-5">Accessible Offline</h3>
                            <p>Technologies like service workers & IndexedDB can be leveraged to ensure your app works regardless of connectivity.</p>
                        </div>
                        <div className="mt-8">
                            <Icon3 />
                            <h3 className="cl-primary mt-5">Cross Browser & Device Compatible</h3>
                            <p>Responsive & accessible on all browsers & devices (desktop, tablet & mobile friendly).</p>
                        </div>
                        <div className="mt-8">
                            <Icon4 />
                            <h3 className="cl-primary mt-5">App Like</h3>
                            <p>PWAs can feel exactly like a native app, and even leverage features such as push notifications.</p>
                        </div>
                        <div className="mt-8">
                            <Icon5 />
                            <h3 className="cl-primary mt-5">Easier To Maintain</h3>
                            <p>Maintain only 1 code base instead of 3, and forget about dealing with app store approvals.</p>
                        </div>
                        <div className="mt-8">
                            <Icon6 />
                            <h3 className="cl-primary mt-5">Discoverable, Linkable & Installable</h3>
                            <p>PWAs are just websites with special features, so you can still link to "pages" and optimise for search engines.</p>
                        </div>
                    </div>

                    <p className="text-center mt-6"><button onClick={() => modalContext.openModal('contact-modal')} className="btn btn-lg btn-green">Get in Touch</button></p>
                </div>
            </div>

            {/* Use Cases */}
            <div id="use-cases" className="component bg-darker double-padding">
                <div className="container">
                    <div className="w-full mx-auto">
                        <h2 className="text-center">Use Cases<span className="symbol symbol-purple"><span></span></span></h2>
                        <p className="text-center text-larger">We've developed web applications for <strong>financial institutions</strong>, <strong>gyms</strong>,<br className="hidden lg:block" /> <strong>tour operators</strong>, <strong>building managers</strong>, <strong>real estate companies</strong> and more.</p>
                        <p className="text-center mt-12 mb-12 cl-primary">We typically build web apps that fall into one or more of the following categories:</p>

                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                            <div className="card card-normal card-green pt-0">
                                <Icon7 className="icon-white" />
                                <h3 className="text-border-left">Dashboards & Portals</h3>
                            </div>
                            <div className="card card-normal card-blue pt-0">
                                <Icon8 className="icon-white" />
                                <h3 className="text-border-left">Consumer Applications</h3>
                            </div>
                            <div className="card card-normal card-purple pt-0">
                                <Icon9 className="icon-white" />
                                <h3 className="text-border-left">Business / Process Automation</h3>
                            </div>
                            <div className="card card-normal card-primary pt-0">
                                <Icon10 className="icon-white" />
                                <h3 className="text-border-left">API Integration & Development</h3>
                            </div>
                        </div>
                        <p className="text-center mt-12 mb-0"><a href="mailto:info@artificial.studio" className="btn btn-blue btn-lg">Email Us</a></p>
                    </div>
                </div>
            </div>

            {/* We're agency friendly */}
            <AgencyFriendly />

            {/* Examples */}
            <RecentWork className="double-padding-top" title="Examples" type={['app']} />

            {/* Our Process */}
            <div id="process" className="component double-padding-top vh-100 pb-32">
                <h2 className="text-center mb-0">Our Process<span className="symbol symbol-green"><span></span></span></h2>
                <Process data={processJson} />
                <div className="top-gradient-darker"></div>
            </div>

            {/* Get in Touch */}
            <GetInTouch />
        </div>

    )
}
